import React, { useRef, useState } from 'react'
import dummyImage from '../assets/Professionals/DummyImage.png'
import Filter from '../components/Filter'
import StylistAppointments from '../components/StylistAppointments'
import AllMembers from '../components/AllMembers';
import Profile from '../components/Profile';
import useLocalStorage from '../hooks/useLocalStorage';
import getToday from '../utility/getToday';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import formatDateString from '../utility/getDateInFormat';
import { useParams } from 'react-router-dom';
import { FaRegCalendarAlt } from "react-icons/fa";

export default function ProfessionalBooking({}) {
    const {id} = useParams();
  const [filter, setFilter] = useLocalStorage('filter', id || -2)
  const [filterOptions, setFilterOptions] = useState(false);
  const TODAY_DATE = getToday();
  const [date, setDate] = useState(TODAY_DATE);
  const inputRef = useRef();
    
  const handleDateClick = (date) => {
    setDate(formatDateString(date));
    console.log(formatDateString(date));
  };

  return (
    <div className='flex flex-col justify-start h-screen px-4'>
      <div>
        {
            <StylistAppointments setFilter={setFilter} id={id} date={date} handleDateClick={handleDateClick} />
            // <AllMembers active={true} setFilter={setFilter} setFilterOptions={setFilterOptions} date={date} handleDateClick={handleDateClick} /> 
        }
      </div>
      <div className='flex   justify-center w-full gap-5'>
        <button id='filter' className='flex justify-center gap-3 border border-white text-white rounded-lg items-center text-base py-1.5 px-2 w-full mt-4' onClick={() => setFilterOptions(!filterOptions)}>
          Filter
        </button>

        <button id='filter' className='flex justify-center relative gap-3 border border-white text-black rounded-lg items-center text-base py-1.5 px-2 w-full mt-4'>
        <DatePicker selected={date} onChange={(date) => {
          handleDateClick(date)}
        } />
      <FaRegCalendarAlt className='text-white z-50 absolute right-2' /> 

        </button>
      </div>

      <Filter filterOptions={filterOptions} setFilterOptions={setFilterOptions} setFilter={setFilter} />

    </div>
  )
}
