import moment from 'moment';

function generateTimeSlots(startTime, endTime) {
  const timeSlots = [];
  const timeFormat = 'HH:mm';

  const start = moment(startTime, timeFormat);
  const end = moment(endTime, timeFormat);

  while (start.isBefore(end)) {
    timeSlots.push(start.format(timeFormat));
    start.add(15, 'minutes');
  }

  return timeSlots;
}

export default generateTimeSlots;
