import { initializeApp } from "firebase/app";
import axios from "axios";
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
const firebaseConfig =  {
  apiKey: "AIzaSyCBbrmd3xRpyB0xmStl8SpseyiRGHbJfHk",
  authDomain: "stylin-96caf.firebaseapp.com",
  projectId: "stylin-96caf",
  storageBucket: "stylin-96caf.appspot.com",
  messagingSenderId: "430273813683",
  appId: "1:430273813683:web:8ceb2b089083389504e4c6",
  measurementId: "G-LFZMSBBPH1"
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);
const API_ENDPOINT = process.env.REACT_APP_API_URL;

export const requestPermission = async() => {

  console.log("Requesting User Permission......");
  Notification.requestPermission().then((permission) => {

    //  navigator.serviceWorker.register('sw.js').then(function(reg) {
    //   if(reg.installing) {
    //          console.log('Service worker installing');
    //      } else if(reg.waiting) {
    //          console.log('Service worker installed');
    //      } else if(reg.active) {
    //          console.log('Service worker active');
    //      }
    //  })

    if (permission === "granted") {
      console.log("Notification User Permission Granted."); 
      return getToken(messaging, { vapidKey: `BCKgLMTldTreg13UBjOV2CKqmMVJ-El6tFLTLZc8ajIDfESTccmZxGvNvaVkcd1GLGAWHEFKfwr4N43TWpxTXyU` })
        .then((currentToken) => {

          const requestData = {
            "shop_id": 1,
            "token_value":currentToken }

          if (currentToken) {
            axios.post(`${API_ENDPOINT}/shop/setTokenKeysFcm`, requestData, {
              headers: {
                  "Content-Type": "application/json",
                  accept: "/",
                  Authorization:' Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI1IiwidHlwZSI6InBob25lTnVtYmVyIiwiaWRlbnRpZmllciI6IjkzODE2MjQ0MDMiLCJpYXQiOjE3MTYzNjQ4OTcsImV4cCI6MTcxODk1Njg5N30.nPThL6-SA9rqeBAOpwDJkUyiloYCGK1hVi5Ad7W_8oU',

                  "Access-Control-Allow-Origin": "*",
              }
          }).then((res) => {

          console.log('token Added up',res)
          }).catch((error) => {
             console.log(error);
          });

            console.log('Client Token: ', currentToken);
          } else {
            
            console.log('Failed to generate the app registration token.');
          }
        })
        .catch((err) => {

          console.log('An error occurred when requesting to receive the token.', err);
        });
    } else {

      console.log("User Permission Denied.");
    }
  });

}

requestPermission();


export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
});