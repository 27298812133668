import React, { useState, useEffect } from "react";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { useAddMember } from "../contexts/addMemberContext";

export default function Select({
  options,
  multiple,
  placeholder,
  visible,
  fieldName,
  key,
  onChange,
  isEdit,
  name,
  addSelectedOption
}) {
  console.log(visible, name);
  const [hide, setHide] = useState(!(visible == name));
  const [isArrowDown, setIsArrowDown] = useState(true);
  const [genderVal, setGenderVal] = useState("");
  const [selectedOption, setSelectedOption] = addSelectedOption;
  const [services, setServices] = useState([]);
  const { handleGender, handleService } = useAddMember();

  const [selectedValues, setSelectedValues] = useState(() => {
    return multiple ? [] : "";
  });
  useEffect(() => {
    setHide(!(visible == name));
    console.log("HIDE CHANGED", name, hide);
  }, [visible, name]);
  console.log("selectedValue", selectedValues);
  function getValueById(id) {
    const matchingOption = options.find((option) => option.id === id);
    return matchingOption ? matchingOption.value : undefined;
  }
  useEffect(() => {
    console.log(placeholder, multiple);
    console.log("Selected Values: ", selectedValues);
    setSelectedOption(
      options
        .map((option) => selectedValues.includes(option.id) && option.value)
        .filter((option) => option != false)
    );
  }, [selectedValues]);

  console.log("shit change", genderVal);

  const handleSelect = (id, name) => {
    onChange();

    // Update the services array if the field is offered_services
    if (fieldName === "offered_services") {
        const updatedServices = [...services, name];
        setServices(updatedServices);
        handleService(updatedServices);
    }

    // Handle gender selection
    if (fieldName === "gender") {
        setHide(!hide);
        handleGender(name);
    }

    // Update the selected values
    let newArr = [];
    if (multiple) {
        if (selectedValues.includes(id)) {
            newArr = selectedValues.filter((value) => value !== id);
        } else {
            newArr = [...selectedValues, id];
        }
    } else {
        if (selectedValues[0] === id) {
            newArr = [];
        } else {
            newArr = [id];
        }
    }
    setSelectedValues(newArr);
};

  useEffect(() => {
    console.log("services chill", typeof placeholder, fieldName);
  });
  return (
    <div key={key} onBlur={() => setHide(true)} className="relative">
      <div
        id="display"
        onClick={() => {
          setHide(!hide);
          setIsArrowDown(!isArrowDown);
        }}
        className="bg-white h-12 py-3 px-4   rounded-lg w-full flex items-center overflow-x-auto space-x-2 border border-gray-300 hide-scrollbar"
      >
        {selectedValues.length < 1 ? (
          <div className="flex  items-center gap-3 w-full  hide-scrollbar  overflow-x-auto">
            {isEdit
              ? fieldName == "gender"
                ? placeholder
                : placeholder &&
                  placeholder.map((item, key) => (
                    <p className="rounded-full capitalize px-4 py-1 text-xs border border-black whitespace-nowrap">
                      {item}
                    </p>
                  ))
              : placeholder}
          </div>
        ) : multiple ? (
          selectedValues.map((id) => (
            <div
              key={id}
              className="rounded-full px-4 py-1  text-xs border border-black whitespace-nowrap"
            >
              {getValueById(id)}
            </div>
          ))
        ) : (
          <div className="whitespace-nowrap">
            {getValueById(selectedValues[0])}
          </div>
        )}
        <div className="absolute top-0 right-2 flex items-center justify-center h-full ">
          <MdKeyboardArrowDown
            className={`${isArrowDown ? "" : "hidden"} text-xl text-w hite`}
          />
          <MdKeyboardArrowUp
            className={`${isArrowDown ? "hidden" : ""} text-xl text-w hite`}
          />
        </div>
      </div>
      <div
        className={`absolute z-10 bg-white w-full px-2 py-3 rounded border border-gray-300 mt-1 ${
          hide ? "hidden" : "block "
        }`}
      >
        {options.map((option) => (
          <div
            key={option.id}
            className={`px-2 py-1 capitalize rounded-md cursor-pointer  ${
              multiple
                ? selectedValues.includes(option.id)
                  ? "bg-themeYellow my-1"
                  : ""
                : selectedValues === option.id
                ? "bg-themeYellow"
                : ""
            }`}
            onClick={() => handleSelect(option.id, option.value)}
          >
            {option.value}
          </div>
        ))}
      </div>
    </div>
  );
}
