import React, { useContext, useState, useEffect, useRef } from 'react';
import  { AuthContext } from '../contexts/useAuth.jsx';
// import { IoEyeOutline } from "react-icons/io5";
// import { IoEyeOffOutline } from "react-icons/io5";
// import sideImage from '../assets/sideImageLogin.jpeg';
import axios from 'axios';
import { loginPage } from "../data/login.js";
import { registerPage } from "../data/register.js";
import { RxCross1 } from "react-icons/rx";
import Input from '../components/Input.jsx';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export default function Auth({ setAuth }) {
    
    const navigate = useNavigate();
    const { handleSignIn,handleAuthToken } = useContext(AuthContext)
    const [otpSent, setOtpSent] = useState(false)
    const { isLoginScreen, handleIsLoginScreen } = useContext(AuthContext);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [otp,setOtp] = useState('')
    const [showLoading, setShowLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState('');
    const auth = isLoginScreen ? loginPage : registerPage;
    const API_ENDPOINT = process.env.REACT_APP_API_URL;
    const [timer, setTimer] = useState(30);
    const [showResendTime, setShowResendTime] = useState(false);
    const [showResendBtn, setShowResendBtn] = useState(false);

    const formRef = useRef(null);
    
    useEffect(() => {
        let intervalId;
    
        if (timer > 0) {
          intervalId = setInterval(() => {
            setTimer((prevTimer) => prevTimer - 1);
          }, 1000);
        }
    
        return () => clearInterval(intervalId);
      }, [timer]);




    const sendOtp = (phoneNumber) => {
        setTimer(30);
        const requestData ={
            "phoneNumber": phoneNumber,
            "is_partner": true
        
        }
        try {
            axios.post(`${API_ENDPOINT}/auth/signInWithNumber`, requestData, {
                headers: {
                    "Content-Type": "application/json",
                    accept: "/",
                    "Access-Control-Allow-Origin": "*",
                }
            }).then((res) => {
                setOtp(Number(res.data.otp))
                setShowLoading(false)
          setShowResendTime(true);
          setShowResendBtn(true);


                if (res.status === 200) {
                    toast.success("Otp sent Successfully");
                    setOtpSent(true)
                    
                }

            }).catch((error) => {
                console.log('error',error)
                setShowLoading(false)

                if (error.response) {
                    if (error.response.status === 404) {
                        toast.error("The account doesn't exist. Create a new one")
                    } else if (error.response.status === 409) {
                        toast.error("Account already exists");
                        
                    } 
                     else if (error.response.status === 401) {
                        toast.error("Unauthorized. OTP mismatch");
                        
                    } 
                    else {
                        toast.error(error.response.data?.message || "Sign up failed. Please try again later.");
                    }
                } else {
                    toast.error("Something went wrong. Please try again later.");
                }
            });
        }
        catch (err) {
            console.log('error: ', err)
        }
    }

    const handleVerifyOtp = (phoneNumber, otp)=>{
        axios
        .post(
          `${API_ENDPOINT}/auth/verifyNumberSignIn`,
          {
            phoneNumber: phoneNumber,
            is_partner: true,
            otp,
          },
          {
            headers: {
              "Content-Type": "application/json",
              accept: "application/json",
              "Access-Control-Allow-Origin": "*",
            },
          }
        )
        .then((res) => {
          console.log("response for verifying otp: ", res.data.accessToken);
          handleAuthToken("Bearer " + res.data.accessToken);
        //   handleVerifyOtp(false)
        setShowLoading(false)
          if (res.status === 200) {
            toast.success("Otp Verified Successfully");
            sessionStorage.setItem("__signIn__",true)
            // setSignedIn(true);
            navigate("/home");
            // setShowOtp(true);
            // setShowOtpButton(true);
          }
  
          //   setShowLoading(false);
        })
        .catch((error) => {
          console.log("error", error);
          //   setShowLoading(false);
          setShowLoading(false)
  
          if (error.response) {
            if (error.response.status === 404) {
              toast.error('OTP not found');
            } else if (error.response.status === 409) {
              toast.error("Account already exists");
              setTimeout(() => {
                // handleSignupForm();
              }, 2500);
            }
            else if (error.response.status === 401) {
                toast.error("Unauthorized. OTP mismatch");
                
            } 
             else {
              toast.error(
                error.response.data?.message ||
                  "Sign up failed. Please try again later."
              );
            }
          } else {
            toast.error("Something went wrong. Please try again later.");
          }
        });
    }
    const handleSubmit = (e) => {
        // console.log(process.env.BACKEND)
        e.preventDefault();
        const formData = new FormData(e.target);
        const data = Object.fromEntries(formData.entries());
        // console.log(data)
        if (!isLoginScreen) {
            if (data.name === '') setErrorMessage('Name not entered!');
            else if (data.email === '') setErrorMessage('Email not entered');
            else if (data.phoneNumber === '') setErrorMessage('Phone Number not entered!');
            else {
                let emailAtTheRate = data.email.indexOf('@');
                let emailDot = data.email.lastIndexOf('.');
                if (emailDot != -1 && emailAtTheRate != -1 && emailAtTheRate < emailDot) {
                    if ((data.phoneNumber[0] == 6 || data.phoneNumber[0] == 7 || data.phoneNumber[0] == 8 || data.phoneNumber[0] == 9) && data.phoneNumber.length == 10) {
                        if (e.target.check.checked) {
                            setErrorMessage('')
                            // console.log('sign up done!')
                            // sendPrimaryDetails()
                            handleSignIn()
                        }
                        else {
                            setErrorMessage('Please read the Terms and Conditions!')
                        }
                    }
                    else {
                        setErrorMessage('Enter a valid Phone Number!');
                    }
                }
                else {
                    setErrorMessage('Enter a valid email address!');
                }
            }
        }
        else {
            if (data.phoneNumber === '') setErrorMessage('Phone Number not entered');
            else if (data.otp === '') setErrorMessage('Please add the OTP that you got!');
            else {
                setShowLoading(true)
                if ((data.phoneNumber[0] == 6 || data.phoneNumber[0] == 7 || data.phoneNumber[0] == 8 || data.phoneNumber[0] == 9) && data.phoneNumber.length == 10) {
                    setErrorMessage('');
                    // console.log('login valid')
                    // verifyOTP();
                    if (otpSent) handleVerifyOtp(data.phoneNumber, data.otp)
                    else sendOtp(data.phoneNumber)
                }
                else {
                    setErrorMessage('Enter a valid Phone Number!');
                }
            }

        }




    };




    const handleChange = (e) => {
        // console.log(e.target, e.target.name)
        if (e.target.name == "phoneNumber") {
            console.log(isNaN(Number(e.target.value)));
            if (isNaN(Number(e.target.value))) {
                setErrorMessage("Enter a valid mobile number!")
                e.target.value = phoneNumber;
            }
            else {
                setErrorMessage('');
                setPhoneNumber(e.target.value)
            }
        }
    }

    const handleSwitch = () => {
        setErrorMessage('');
        formRef.current.reset();
        handleIsLoginScreen();
    }
    return (
        <div className="">
              <div className="w-full z-[999] absolute bottom-0">
        <ToastContainer className="toast-container" />
        </div>

        <div className="absolute top-28 w-screen px-4">
            <form ref={formRef} onSubmit={handleSubmit} onChange={handleChange} className="flex flex-col gap-6 bg-black py-6 px-4 rounded-lg font-mukta ">

                <div className='flex justify-between'>
                    <h1 className='text-2xl font-semibold text-white font-lexend'>
                        {auth.heading}
                    </h1>
                    <button onClick={() => setAuth(false)} className='text-white'><RxCross1 /></button>

                </div>
                <div>

                    <div className='flex flex-col gap-3'>
                        {auth.inputFields.map(input => <Input key={input.id} input={input} otpSent={otpSent} otp={otp} />)}
                    </div>

                    {isLoginScreen ?
                        <span className='flex items-center pt-2'>
                            <span className='grow text-red text-xs font-semibold'>{errorMessage ? errorMessage : null}</span>
                            
                            {timer > 0 && showResendTime ? (
                  <p className='text-white text-sm'>
                    Resend OTP in <span className="font-medium text-white"> {timer} </span>{" "}
                    seconds
                  </p>
                ) : ( <span id='resend' onClick={()=>{
                                sendOtp(phoneNumber)
                            }} className={`${
                                showResendBtn ? "" : "hidden"
                              } justify-self-end text-white cursor-pointer hover:text-[#F4C01E] text-xs font-light`}>{otpSent && 'Resend OTP'}</span>)}
                        </span> :
                        <span className='flex flex-col pt-2'>
                            <small className='grow text-red '>{errorMessage || <br />}</small>
                            <div className='text-white items-self-end text-xs font-light'><input type="checkbox" className='mr-1 border-radius-10' name='check' />I have read the <a href="/" className='underline underline-offset-2 cursor-pointer hover:text-[#F4C01E]'>Terms and Conditions</a></div>
                        </span>
                    }
                </div>
                <button
                    type="submit"
                    className='text-base   w-full bg-[#F4C01E] font-medium py-3 px-2 rounded-md hover:bg-[#F7C647] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white'
                >
                    <span className={`${showLoading?'hidden':''}`}>

                    {isLoginScreen ? otpSent ? 'Login' : 'Send OTP' : 'Register'}
                    </span>
                    <span className={`${showLoading?'':'hidden'}`}>
                        Loading...
                    </span>
                </button>
                {/* <span className="text-xs font-light flex gap-1 text-white">
                    <div>
                        {auth.switchLine.question}{' '}
                    </div>
                    <strong
                        onClick={handleSwitch}
                        className=" hover:text-[#F4C01E] cursor-pointer"
                    >
                        {auth.switchLine.actionable}
                    </strong>
                </span> */}
            </form>
            {/* <img src={auth.sideImage.image} alt={auth.sideImage.alt} className="hidden md:block mb-0 absolute right-0 top-20 h-[89vh] w-1/2 object-cover" /> */}
        </div>
        </div>

    );
}
