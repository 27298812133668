import axios from "axios";
import React, { createContext, useEffect, useContext, useState } from "react";
import { AuthContext } from "./useAuth";
const NotificationsContext = createContext({
  notifications: [],
});
const API_ENDPOINT = process.env.REACT_APP_API_URL;
const NotificationsProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);
  const { authToken, shopId, signedIn } = useContext(AuthContext);
  useEffect(() => {
    if (signedIn) {
      axios
        .get(`${API_ENDPOINT}/shop/getShopNotifications?shop_id=${shopId}`, {
          headers: {
            Authorization: authToken || null,
            "Content-Type": "application/json",
            accept: "application/json",
          },
        })
        .then((res) => {
          if (res.status == 200) {
            setNotifications(res.data.shopNotifications);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [signedIn]);
  return (
    <NotificationsContext.Provider value={{ notifications }}>
      {children}
    </NotificationsContext.Provider>
  );
};

export { NotificationsProvider, NotificationsContext };
