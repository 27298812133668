import React, { useState, useEffect, createContext } from "react";
import useLocalStorage from "../hooks/useLocalStorage";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext({
  isLoginScreen: true,
  handleIsLoginScreen: () => {},
  signedIn: false,
  handleSignIn: () => {},
  handleSignOut: () => {},
  authToken: null,
  shopId: 1,
  handleShopId: () => {},
});

const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [isLoginScreen, setIsLoginScreen] = useState(true);
  const [signedIn, setSignedIn] = useState(false)
  const handleIsLoginScreen = () => setIsLoginScreen(!isLoginScreen);
  const API_ENDPOINT = process.env.REACT_APP_API_URL;
  const [shopId, setShopId] = useLocalStorage("shopId", null);
  const singInSta = sessionStorage.getItem('__signIn__')
  // console.log('endpoint', API_ENDPOINT)
  const isSignup = true;
  const [authToken, setAuthToken] = useLocalStorage(
    "authToken",
    "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjMsInR5cGUiOiJwaG9uZU51bWJlciIsImlkZW50aWZpZXIiOiI4Njg4NzQ5NDU4IiwiaWF0IjoxNzE0ODUxOTA3LCJleHAiOjE3MTc0NDM5MDd9.F-_317eDGdlgMhqb7EcHAmXrCtYNUmPBxdAIvubpK1I"
  );
  // const endpoint = "/"
  // const requestData = isSignup ? { phoneNumber: '8168173875' } : { phoneNumber: '8168173875'};
  const [otp, setOtp] = useLocalStorage("");
useEffect(()=>{
  setSignedIn(singInSta)
},[singInSta])

  const handleSignIn = (phoneNumber, otp) => {
    setSignedIn(true)
    console.log("sign in tried", phoneNumber, otp);


    console.log("Signed In");
  };
  const handleSignOut = () => {
    setSignedIn(false);
    console.log("Signed Out");
  };
  const handleShopId = (id) => setShopId(id);
  const handleAuthToken = (token) =>setAuthToken(token)
  return (
    <AuthContext.Provider
      value={{
        isLoginScreen,
        handleIsLoginScreen,
        signedIn,
        handleSignIn,
        handleSignOut,
        authToken,
        shopId,
        handleShopId,
        handleAuthToken
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
export { AuthContext, AuthProvider };
