import React from "react";

export default function PostSuccessScreen({ successMessages, errorMessages, success }) {
  const messages = success ? successMessages : errorMessages
    return (
    <div className={`rounded-xl text-black font-bold ${success ? 'bg-white': 'bg-[#f00]'} flex flex-col items-center h-[276px] justify-center font-lexend text-base text-center fixed left-4 top-20 w-[90vw]`}>
      {messages.map(message=><span>{message}</span>)}
    </div>
  );
}
