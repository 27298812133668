function formatDateString(inputDateStr) {
    const date = new Date(inputDateStr);
    
    if (isNaN(date)) {
      return 'Invalid Date';
    }

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

export default formatDateString;