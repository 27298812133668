import React,{useState,useEffect} from "react";
import axios from 'axios'
import { FaUser } from "react-icons/fa6";
import dummyImage from '../assets/Professionals/DummyImage.png'
export default function StylistTitle({ stylist, setFilter,setFilterOptions }) {
  return (
    <div className="flex flex-col items-center w-12" onClick={()=>{setFilter(stylist.professional_id);setFilterOptions(false)}}>
      {stylist?.profile_image_url ? (
          <img
            src={stylist?.profile_image_url}
            alt=""
            className="w-12 h-12 rounded-full object-cover"
          />
        ) : (
          <div className="text-xl border flex items-center justify-center rounded-full p-3">
            <FaUser />
          </div>
        )}
      <div className="font-medium text-xs truncate">{stylist.first_name || "--stylist-first-name--"}</div>
      <div className="font-medium text-xs truncate">{stylist.last_name || "--stylist-last-name--"}</div>
    </div>
  );
}
