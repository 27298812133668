import React, {useState, useEffect } from "react";
import { FaUser } from "react-icons/fa6";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
export default function FilterStylistCard({stylist,setFilter,setFilterOptions}) {

    const navigate = useNavigate();

    const handleNavigate = (id)=> {
      navigate(`/appointments/${id}`);
    }
    return (
    <button
      className="text-center px-3 py-6 border-b border-red-300 flex gap-3 items-center w-full"
      onClick={() => {
        handleNavigate(stylist?.professional_id);
        setFilter(stylist?.professional_id);
        setFilterOptions(false);
      }}
    >
      {stylist?.profile_image_url ? (
          <img
            src={stylist.profile_image_url}
            alt=""
            className="w-10 h-10 rounded-full object-cover"
          />
        ) : (
          <div className="text-xl border flex items-center justify-center rounded-full p-3">
            <FaUser />
          </div>
        )}
      <div className="flex flex-col gap-1">
        <div className="text-base">
          {stylist.first_name + " " + stylist.last_name}
        </div>
        <div className="text-xs italic">View Profile</div>
      </div>
    </button>
  );
}
