import React, { useState, useEffect, useContext } from "react";
import dummyImage from "../assets/Professionals/DummyImage.png";
import Filter from "./Filter";
import customers from "../data/customers";
import services from "../data/services";
import appointmentsList from "../data/appointments";
import generateTimeSlots from "../utility/generateTimeSlots";
import stylists from "../data/stylists";
import Profile from "../components/Profile";
import AddMember from "./AddMember";
import axios from "axios";
import { AuthContext } from "../contexts/useAuth";
import LoadingScreen from "./LoadingScreen";
import ErrorMessage from "./ErrorMessage";
import { FaUser } from "react-icons/fa6";
import BookingCard from "./BookingCard";
import { toast } from "react-toastify";

export default function Appointment({ id, date }) {
  const { authToken, shopId } = useContext(AuthContext);
  // const [chronoFilter,setChronoFilter] = useState(0)
  // const appointments = appointmentsList[id-1]
  const [windows, setWindows] = useState(0);
  const [stylist, setStylist] = useState({});
  const [stylistLoading, setStylistLoading] = useState(true);
  const [bookingsLoading, setBookingsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(0);
  // const stylist = stylists.find(stylist => stylist.id == id)
  const API_ENDPOINT = process.env.REACT_APP_API_URL;
  const [imageValid, setImageValid] = useState(null);
  const [bookings, setBookings] = useState([
    {
      booking_id: 1,
      slot_time: "10:00AM",
      customer_name: "John Doe",
    },
    {
      booking_id: 49,
      slot_time: "11:00AM",
      customer_name: "Jane Smith",
    },
    {
      booking_id: 48,
      slot_time: "4:30PM",
      customer_name: "Michael",
    },
  ]);

  const [showModal, setShowModal] = useState(false);
  const [booking_id, setBookingId] = useState();

  // console.log(stylist)
  // useEffect(() => {
  //   axios
  //     .get(
  //       `${API_ENDPOINT}/professional/getProfessionalDetails?professional_id=${id}`,
  //       {
  //         headers: {
  //           Authorization: authToken,
  //           "Content-Type": "application/json",
  //           accept: "application/json",
  //         },
  //       }
  //     )
  //     .then((res) => {
  //       // console.log('stylist',res)
  //       if (res.status == 200) {
  //         setStylist(res.data.stylist);
  //         setStylistLoading(false);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log("error: ", err);
  //       setErrorMessage(1);
  //       setStylistLoading(false);
  //     });
  // }, [id]);

  // useEffect(() => {
  //   // axios
  //   //   .get(stylist.profile_image_url)
  //   //   .then((res) => {
  //   //     if (res.status == 200) {
  //   //       setImageValid(true);
  //   //     }
  //   //   })
  //   //   .catch((err) => {
  //   //     console.log(err);
  //   //     setImageValid(false);
  //   //   });
  //   stylist.profile_image_url ? setImageValid(true) : setImageValid(false);
  //   console.log("image", stylist.profile_image_url);
  // }, [stylist]);

  const handleCancel = (cancel, booking) => {
    axios
      .post(
        `${API_ENDPOINT}/booking/updateBooking`,
        {
          status: cancel ? "cancel" : "done",
          booking_id: booking,
        },
        {
          headers: {
            Authorization: authToken,
            "Content-Type": "application/json",
            accept: "application/json",
          },
        }
      )
      .then((res) => {
        if (res.status == 200 || res.status==201) {
          toast.success("Slots updated successfully");
          setShowModal(false);
          window.location.reload();
        }
        else{
          setShowModal(false);
          toast.error("Slots not updated");
        }
      })
      .catch((err) => {
        console.log("error", err);
        toast.success("Slots updated successfully");
        setShowModal(false);
      });
  };


  useEffect(() => {
    axios
      .get(
        `${API_ENDPOINT}/shop/getShopBookingsProfessional?shop_id=${shopId}&appointment_date=${date}&professional_id=${id}`,
        {
          headers: {
            Authorization: authToken,
            "Content-Type": "application/json",
            accept: "application/json",
          },
        }
      )
      .then((res) => {
        if (res.status == 200) {
          console.log("bookings", res.data.result);
          setBookings(res.data.result);
          setBookingsLoading(false);
          setStylist(res.data.stylist);
          setStylistLoading(false);
        }
        // setAvailableStylists(res.data.availableStylists)
      })
      .catch((err) => {
        console.log("error: ", err);
        setErrorMessage(2);
        setBookingsLoading(false);
      });
  }, [date, id]);


  const timeSlots = generateTimeSlots("10:00", "19:45");
  bookings.map(
    (booking) => (booking.slot_time = booking.slot_time.replace(" ", ""))
  );
  // console.log(bookings.find(booking => booking.slot_time === "4:30PM"),timeSlots)

  return (
    <>
      <div className="flex flex-col gap-3.5 justify-start">
        {stylistLoading ? (
          <LoadingScreen value={"Stylist Information"} />
        ) : errorMessage === 1 ? (
          <ErrorMessage value={"Issue faced while fetching Stylist Info."} />
        ) : (
          <div className="flex items-center text-white gap-3">
            {stylist?.profile_image_url ? (
              <img
                src={stylist?.profile_image_url}
                alt=""
                className="w-10 h-10 rounded-full object-cover"
              />
            ) : (
              <div className="text-xl border flex items-center justify-center rounded-full p-2">
                <FaUser />
              </div>
            )}
            <div>
              <div className="text-base font-semibold w-40">
                {stylist.first_name + " " + stylist.last_name}
              </div>
              <button className="text-xs italic" onClick={() => setWindows(1)}>
                View profile
              </button>
            </div>
            <div
              className={`py-1 px-2 text-xs w-max  ${
                stylist.active
                  ? "text-[#36A03B] bg-[#D2FFD1]"
                  : "bg-[#FFA6A6] text-[#f00]"
              } rounded-full ml-auto`}
            >
              {stylist.active ? "Active" : "On Leave"}
            </div>
          </div>
        )}
        <div
          className={`${
            errorMessage === 2 ? "bg-black" : "bg-white"
          } text-black overflow-auto h-[60vh] w-full`}
        >
          {bookingsLoading ? (
            <LoadingScreen value={"Stylist Bookings"} dark={true} />
          ) : !errorMessage === 2 ? (
            <ErrorMessage
              value={"Issue faced while fetching Stylist Booking Info."}
            />
          ) : (
            <table className="border-collapse">
              <thead>
                <th className="text-[10px] font-bold py-2.5 px-7">Slots</th>
                <th className="text-[10px] font-bold py-2.5 px-7 w-full">
                  Bookings
                </th>
              </thead>
              <tbody className="bg-white">
                {timeSlots.map((timeSlot) => (
                  <tr key={timeSlot} className="border-solid border-b-2">
                    <td className="border text-[10px] font-medium text-center border-[#6F6F6F] text-[#6F6F6F] py-4 px-4.5">
                      {timeSlot}
                    </td>
                    { bookings.find((booking) => booking.slot_time === timeSlot) ? 
                  <BookingCard bookings={bookings} timeSlot={timeSlot} setShowModal={setShowModal} setBookingId = {setBookingId} />
                  : <></> }
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
        
        {
  showModal ? 
  <div
          className={`fixed inset-0 flex items-center justify-center z-50`}
        >
           <div className="flex flex-col gap-3 w-72 px-4 py-3 rounded-xl border border-black bg-white relative">
           <button
        className="self-end px-2 py-1 font-bold text-regular bg-[#ccc] rounded "
        onClick={() => setShowModal(false)}
      >
        X
      </button>
      <h2 className="text-base font-medium" >Customer Name : <span className="text-sm font-normal">
       {booking_id?.customer_name}  </span> </h2 >
       <h2 className="text-base font-medium" >customer phone : <span className="text-sm font-normal">{booking_id?.customer_phone}</span> </h2 >
       <h2 className="text-base font-medium" >Cost : <span className="text-sm font-normal"> {booking_id?.cost} </span> </h2 >
      <div className="flex  gap-2 justify-center">

          <button
            className="px-6 py-2 font-bold text-regular bg-[#3cd3ad] rounded"
            onClick={() =>
              handleCancel(
                false,
                // bookings.find((booking) => booking.slot_time === timeSlot)4
                booking_id?.booking_id
              )
            }
          >
            Done
          </button>
          <button
            className="px-6 py-2 font-bold text-regular bg-[#bb3737] rounded"
            onClick={() =>
              handleCancel(
                true,
                // bookings.find((booking) => booking.slot_time === timeSlot)
                booking_id?.booking_id
              )
            }
          >
            Cancel
          </button>
          </div>
          </div>
        </div>
        : <></>
}
      </div>
      {windows == 1 && <Profile stylist={stylist} setWindows={setWindows} />}
      {windows == 2 && (
        <AddMember
          stylist={stylist}
          setWindows={setWindows}
          windows={windows}
        />
      )}
    </>
  );
}
