import React,{useState,useContext} from 'react'
import landing1 from '../assets/landing/landing1.png'
import landing2 from '../assets/landing/landing2.png'
import { FiPhoneCall } from "react-icons/fi";
import Auth from './Auth'
import { Link } from 'react-router-dom';
export default function Landing() {

    const [screen,setScreen] = useState(1);
    const [auth,setAuth] = useState(false)
    const handleLogin = ()=>{
      // console.log('Login')
      setAuth(true)
      // handleSignIn()
    }
    const handleRegister = ()=>{
      // console.log('Register')
      // handleSignIn()
    }
  return (
    <>
    <div className={`${auth ? 'opacity-50': ''} flex flex-col  h-screen overflow-hidden text-white`}>
      <div className="bg-black text-center text-2xl h-16 py-4 font-michroma">
        STYLIN
      </div>
      {screen == 2 && 
        <div className='bg-black flex items-center justify-center text-sm py-2'>
            <div className='bg-themeYellow text-black rounded-full flex justify-center items-center mr-1 h-9 w-9'>
                <FiPhoneCall className='text-xl' />
            </div>
            Need Help? Call us now at 7568242321
        </div>
      }
      {screen == 2 && 
      <div className='absolute text-center top-20 w-full z-50 text-lg'>
        Partner with <inline className="font-michroma"> STYLIN</inline>
        </div>}
      <img
        src={`${screen == 1 ? landing1 : landing2}`}
        alt=""
        className=" h-full " // Ensures image fills remaining space
      />
      <div className="flex flex-col bg-black gap-3 m-2.5 px-7 py-5 mb-5">
        {/* Buttons with responsive sizing and margins */}
        <button className="bg-themeYellow px-4 py-2 rounded-md text-lg text-black font-semibold" onClick={()=>{
          if(screen==2) handleRegister()
          else handleLogin()
        }}>
          {screen == 1 ? 'Login' : 'Register Now'}
        </button>

        <button onClick={()=>{
          // setScreen(2)
          // if(screen==2) handleLogin()
        }} className="text-white px-4 py-2 rounded-md text-lg border border-white">
          {/* {screen == 1 ? 'Partner with us' : 'Login'} */}
        <Link to='/contact'>
          Contact Us
          </Link>
        </button>
        {/* <div className="flex justify-center w-full text-[#C8C8C8]">
          <small className='px-3 py-1 w-[146px] text-center text-[10px]'>Terms & Conditions</small>
          <small className='px-3 py-1 w-[146px] text-center text-[10px]'>Privacy Policy</small>
        </div> */}
      </div>
    </div>
      {auth && <Auth setAuth={setAuth}/>}
      </>
  )
}
