import React, { useEffect, useState } from "react";
import { FaCheck } from "react-icons/fa6";
import Select from "./Select";

export default function StylistInput({
  field,
  key,
  values,
  onChange,
  setSelectedServices,
  addSelectedGender
}) {
  const [value, setValue] = useState(values[field.name] || "");
  const [selectedOption, setSelectedOption] = useState(null);
  const  [selectedGender, setSelectedGender] = addSelectedGender
  const [visible, setVisible] = useState(null);
  useEffect(() => {
    if (field.select == "multiple") {
      setSelectedServices(selectedOption);
    } else {
      setSelectedGender(selectedOption);
    }
  }, [selectedOption]);

console.log('prop drill3',values)

  return (
    <div className="w-full">
      {field.tag === "select" ? (
        <div
          onClick={() => {
            setVisible(field.name);
          }}
          id={key}
          className="relative"
        >
          <Select
            key={key}
            multiple={field.select == "multiple"}
            options={field.options}
            visible={visible}
            onChange={onChange}
            fieldName= {field.name}
            isEdit={values.isEdit}
            placeholder={values.isEdit?((field.name=='gender'&&value) || (field.name=='offered_services'&&value))  :field.placeholder}
            name={field.name}
            onClick={() => console.log(field.name)}
            addSelectedOption={ [selectedOption, setSelectedOption] }
          />
      
        </div>
      ) : (
        <div className="relative">

        <input
          type={field.type}
          // disabled={values.isEdit&&field.name=='phone_number'}
          className="py-3 px-4 bg-white   rounded-lg w-full"
          placeholder={field.placeholder}
          name={field.name}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          />
          
{!value&&field.name=='dob' && (
        <span className="absolute top-0  left-0  bg-white py-3 px-4 text-gray-500 pointer-events-none">
          {field.placeholder}
        </span>
      )}

          
          </div>
      )}
    </div>
  );
}
