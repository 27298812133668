import React,{useState} from 'react'
import { IoEyeOutline } from "react-icons/io5";
import { IoEyeOffOutline } from "react-icons/io5";

export default function Input({ input, otpSent,otp }) {
    const [hide,setHide] = useState(true);
    
    
    return (
        <div className={`${input.name === "otp" ? 'flex items-center' : ''}`}>
            <input
                type={input.name === "otp" ? `${hide ? 'password' : 'number'}` : input.type}
                maxLength={input.maxlength}
                pattern={input.pattern}
                name={input.name}
                placeholder={input.placeholder}
                disabled={input.name === "otp" ? !otpSent : false}
                required={otpSent}
                inputMode={input.inputMode}
                className="w-full px-3 py-2 rounded-md border border-gray-900 focus:outline-none focus:ring-1 focus:ring-[#F4C01E] focus:border-[#F4C01E]"
            />
            {input.name === "otp" && <i
                type="button"
                className="-ml-7 focus:outline-none cursor-pointer text-black"
                onClick={() => setHide(!hide)}
            >
                {hide ? <IoEyeOffOutline /> : <IoEyeOutline />}
            </i>}
        </div>
    )
}
