import React,{useEffect,useState,useContext} from 'react'
import { RxCross1 } from "react-icons/rx";
import dummyImage from "../assets/Professionals/DummyImage.png"
import { AuthContext } from '../contexts/useAuth';
// import AddMember from './AddMember';
import axios from 'axios';
import LoadingScreen from './LoadingScreen';
import { FaUser } from 'react-icons/fa6';
export default function Profile({stylist,setWindows,loading}) {
    // console.log('this is the stylist: ',stylist)
    const userInputFields = [
        {
            name:'jobTitle',
            type:'text',
            placeholder:'Job Title'
        },
        {
            name:'firstName',
            type:'text',
            placeholder:'First Name'
        },
        {
            name:'lastName',
            type:'text',
            placeholder:'Last Name'
        },
        {
            name:'email',
            type:'email',
            placeholder:'Email'
        },
        {
            name:'gender',
            type:'text',
            placeholder:'Gender'
        },
        {
            name:'dob',
            type:'date',
            placeholder:'Date of Joining'
        },
    ]
    
  return (
    <div className='px-5 py-3.5 absolute top-20 rounded-xl bg-black text-white w-[90vw] border'>
        <div className='flex justify-between mb-6'>
            <div className='text-lexend text-2xl font-medium'>Stylist Profile</div>
            <button onClick={()=>setWindows(0)}><RxCross1/></button>
        </div>
        <div>
            <div className='flex flex-col items-center gap-3 mb-4'>
                <img src={stylist.profile_image_url} alt="" className='h-20 w-20 rounded-full' />
                {/* <div className="text-4xl border p-3 rounded-full">
                            <FaUser/>
                            </div>  */}
           
            </div>
            {loading ? <LoadingScreen value={"Stylist Information"}/> : 
            <div className='text-black flex flex-col gap-2 h-[30vh] overflow-y-auto text-white'>
                <div>Name: {stylist.first_name +' '+ stylist.last_name  }</div>
                {/* <div>Job Title: {stylist.jobTitle}</div> */}
                <div>Gender: {stylist.gender || "--stylist-gender--"}</div>
                <div>Phone Number: {stylist.phone_number || "--stylist-number--"}</div>
                <div>Email: {stylist.email_id || "--stylist-email--"}</div>
                <div>Active: {stylist.active ? 'Active' : 'On-Leave'}</div>
                {/* <div>Address: {stylist.address}</div> */}
                <div>Date of Birth: {stylist.dob || "--stylist-dob--"}</div>
                <div>Experience: {stylist.years_of_experience || "--stylist-experience--"} years</div>
                <div>Services Offered: 
                    <br />
                    {stylist?.offered_services?.join(" , ") || "--services-offered--"} 
                    {/* TODO:// fix this Bug for join method its landing on white screen properly handle join method  */}
                </div>
                
                
            </div>}
            <button className='text-base py-3 rounded-lg text-center bg-themeYellow w-full text-black mt-6' onClick={()=>setWindows(2)}>
                    Edit
                </button>
        </div>
    </div>
  )
}
