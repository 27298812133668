import React, { useEffect, useContext, useState, useRef } from "react";
import axios from "axios";
import { AuthContext } from "../contexts/useAuth";
import dummyImage from "../assets/Professionals/DummyImage.png";
import generateTimeSlots from "../utility/generateTimeSlots";
import LoadingScreen from "./LoadingScreen";
import StylistTitle from "./StylistTitle";
import NullMessage from "./NullMessage";
import getToday from "../utility/getToday";
import { toast } from "react-toastify";

export default function AllMembers({ setFilter, setFilterOptions, date}) {
  const [stylists, setStylists] = useState([]);
  const { authToken, shopId } = useContext(AuthContext);
  const timeSlots = generateTimeSlots("10:00", "19:45");
  const API_ENDPOINT = process.env.REACT_APP_API_URL;
  const [loading, setLoading] = useState(true);
  const [nullMessage, setNullMessage] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [booking_id, setBookingId] = useState();

  const handleCancel = (e,cancel, booking_id) => {
    e.preventDefault()
    axios
      .post(
        `${API_ENDPOINT}/booking/updateBooking`,
        {
          status: cancel ? "cancel" : "done",
          booking_id: booking_id,
        },
        {
          headers: {
            Authorization: authToken,
            "Content-Type": "application/json",
            accept: "application/json",
          },
        }
      )
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          toast.success("Booking updated successfully");
          setShowModal(false);
          window.location.reload();
        }
        else{
          setShowModal(false);
          toast.error("Slots not updated");
        }
      })
      .catch((err) => {
        console.log("error", err);
        toast.success("Booking updated successfully");
        setShowModal(false);
      });
  };

  const handleBookingUpdate = (booking) => {
    // if(booking?.booking_status === "accepted"){
      setShowModal(true);
      setBookingId(booking);
    // }
    toast.warning("Booking is already Updated");
  }

  useEffect(() => {
    axios
      .get(
        `${API_ENDPOINT}/shop/getShopBookings?shop_id=${shopId}&appointment_date=${date}&is_week_view=false`,
        {
          headers: {
            Authorization: authToken,
            "Content-Type": "application/json",
            accept: "application/json",
          },
        }
      )
      .then((res) => {
        if (res.status == 200) {
          setStylists(res.data.result);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
        setNullMessage(true);
      });
  }, [date]);

  return (
    <div className="text-black overflow-auto h-[70vh] w-full">
      {loading ? (
        <LoadingScreen value={"Bookings"} />
      ) : nullMessage ? (
        <NullMessage
          value={"No Bookings are there for your Saloon yet!"}
          whiteText={true}
        />
      ) : (
        <table className="border-collapse">
          <thead>
            <th className="text-[10px] font-bold py-2.5 px-7"></th>
            {stylists.map((stylist) => (
              <th
                key={stylist.professional_id}
                className="text-xs font-semibold py-2.5 px-7 w-[232px] text-white"
              >
                <StylistTitle
                  stylist={stylist}
                  setFilter={setFilter}
                  setFilterOptions={setFilterOptions}
                />
              </th>
            ))}
          </thead>
          <tbody className="bg-white">
            {timeSlots.map((timeSlot) => (
              <tr key={timeSlot} className="border-solid border-b-2">
                <td className="border text-[10px] font-medium text-center border-[#6F6F6F] text-[#6F6F6F] py-4 px-4.5">
                  {timeSlot}
                </td>
                {stylists.map((stylist) => (
                  <td
                    key={`${stylist.professional_id}-${timeSlot}`}
                    className="border text-[10px] font-medium text-center px-4.5"
                  >
                    {stylist.bookings[date]?.map((booking,key) =>
                      booking.slot_time.replace(" ", "") === timeSlot ? (
                        <div className={`px-2 py-1 text-xs ${booking?.booking_status === "cancel" &&'bg-[#CBFBCA]' }  ${booking?.booking_status === "done" &&'bg-[#A3E1DC]' }  bg-[#FFA6A6]` }
                        onClick={() => handleBookingUpdate(booking)}
                        >
                          <div className="">{booking.customer_name&&booking.customer_name.split(' ')[0]}</div>
                          <div className="capitalize">
                            {console.log('booking Details',booking)}
                           <button className={`${key<1?'':'hidd en'} border px-2 py-[1px] my-2 rounded-md text-[9px] bg-[#43414077] font-light text-white`}>
                            View Details
                            </button>  
                            {/* {booking&&booking.sub_services.length} */}
                          </div>
                        </div>
                      ) : null
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      )}

{
  showModal ? 
  <div
          className={`fixed bg-[#2829294c]  inset-0 flex items-center justify-center z-50`}
        >
           <div className="flex flex-col gap-3 w-72 px-4 pt-10 pb-20  rounded-xl  shadow-lg border border-white bg-white relative">
           <button
        className="self-end absolute top-3 px-2 py-1 font-bold text-regular bg-[#ccc] rounded"
        onClick={() => setShowModal(false)}
      >
        X
      </button>
      <h2 className="text-base font-medium" >Customer Name : <span className="text-sm font-normal">
       {booking_id?.customer_name}  </span> </h2 >

       <h2 className="text-base font-medium" >Customer Phone : <span className="text-sm font-normal">{booking_id?.customer_phone}</span> </h2 >
     
       <h2 className="text-base font-medium" >Service Details : <span className="text-sm capitalize font-normal">{booking_id?.sub_services.join(', ')}</span> </h2 >
       
       
       <h2 className="text-base font-medium" >Cost : <span className="text-sm font-normal">₹ {booking_id?.cost} </span> </h2 >
<div className="flex gap-2 items-center m-auto w-full  justify-center ">
      <div className="absolute bottom-5 space-x-5 ">

          <button
            className="px-6 py-2 font-bold text-sm bg-[#A3E1DC] w-24 rounded-md"
            onClick={(e) =>{
            
              handleCancel(
                e,
                false,
                // bookings.find((booking) => booking.slot_time === timeSlot)4
                booking_id?.booking_id
              )
            }
            }
          >
            Done
          </button>
          <button
            className="px-6 py-2 font-bold text-sm bg-[#E6A57E] w-24 rounded-md"
            onClick={(e) =>{
              e.preventDefault()
              handleCancel(
                e,
                true,
                // bookings.find((booking) => booking.slot_time === timeSlot)
                booking_id?.booking_id
              )
            }
            }
          >
            Cancel
          </button>
          </div>
          </div>
          </div>
        </div>
        : <></>
}

    </div>
  );
}
