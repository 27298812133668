import React, { useMemo, useState, useContext, useEffect } from "react";
import { RxCross1 } from "react-icons/rx";
import dummyImage from "../assets/Professionals/DummyImage.png";
import axios from "axios";
import StylistInput from "../components/StylistInput";
import { AuthContext } from "../contexts/useAuth";
import LoadingScreen from "./LoadingScreen";
import PostSuccessScreen from "./PostSuccessScreen";
import { useAddMember } from "../contexts/addMemberContext";

export default function AddMember({ windows, setWindows, stylist, loading }) {
  const { authToken, shopId } = useContext(AuthContext);
  const [services, setServices] = useState([]);
  const API_ENDPOINT = process.env.REACT_APP_API_URL;
  const [selectedImage, setSelectedImage] = useState();
  const [memberAdded, setMemberAdded] = useState(false);
  const [success, setSuccess] = useState(false);
  const [imgUploaded, setImgUploaded] = useState(false);
  const [makeSingleReq, setMakeSingleReq] = useState(true)
  const [selectedServices, setSelectedServices] = useState([]);
  const {sltGender,sltServices} = useAddMember()
const [showLoading, setShowLoading] = useState(false)
  const [selectedGender, setSelectedGender] = useState(null);
  const [makeRequest, setMakeRequest] = useState(false)
  const [formData, setFormData] = useState({
    first_name: stylist?stylist.first_name:"",
    last_name: stylist?stylist.last_name:"",
    offered_services: stylist?stylist.offered_services:sltServices,
    years_of_experience: stylist?stylist.years_of_experience:"",
    profession:stylist?stylist.profession:"",
    shop_id: shopId,
    email_id: stylist?stylist.email_id:"",
    phone_number: stylist?stylist.phone_number:"",
    dob: stylist?stylist.dob:"",
    gender:stylist?stylist.gender: sltGender,
    profile_image_url: stylist?stylist.profile_image_url:"",
  });
  const [imageUrl, setImageUrl] = useState(false);
  const [imgError, setImgError] = useState(false);
  const [errors, setErrors] = useState({});
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    axios
      .get(`${API_ENDPOINT}/shop/getShopSubCategories?shop_id=${shopId}`, {
        headers: {
          Authorization: authToken,
          "Content-Type": "application/json",
          accept: "application/json"
        }
      })
      .then((res) => {
        if (res.status === 200) {
          setServices(
            res.data.services.map((service, index) => ({
              id: index + 1,
              value: service
            }))
          );
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, [API_ENDPOINT, authToken, shopId]);

  console.log('shit chaged',sltServices)

  const [userInputFields, setUserInputFields] = useState([
    {
      tag: "input",
      name: "profession",
      type: "text",
      placeholder: "Job Title",
      required: true
    },
    {
      tag: "input",
      name: "first_name",
      type: "text",
      placeholder: "First Name",
      required: true
    },
    {
      tag: "input",
      name: "last_name",
      type: "text",
      placeholder: "Last Name",
      required: true
    },
    {
      tag: "input",
      name: "email_id",
      type: "email",
      placeholder: "Email",
      required: true
    },
    {
      tag: "input",
      name: "phone_number",
      type: "text",
      placeholder: "Phone Number",
      required: true
    },
    {
      tag: "input",
      name: "years_of_experience",
      type: "number",
      placeholder: "Years of Experience",
      required: true
    },
    {
      tag: "select",
      select: "single",
      name: "gender",
      type: "text",
      placeholder: "Gender",
      options: [
        {
          id: 1,
          value: "Male"
        },
        {
          id: 2,
          value: "Female"
        },
        {
          id: 3,
          value: "Other"
        }
      ],
      required: true
    },
    {
      tag: "input",
      name: "dob",
      type: "date",
      placeholder: "Date of Birth",
      required: true
    },
     
  ]);

  const handleChange = (e) => {
    setMakeRequest(true)
    console.log('errossssssssss icalle')

    const name = e&&e.target.name;
    const value =e&& e.target.value;

    setFormData({
      ...formData,
      [name]:
        name === "gender"
          ? sltGender
          : name === "offered_services"
          ? sltServices
          : value
    });

  };


  useEffect(() => {
    if (memberAdded) {
      setTimeout(() => {
        setMemberAdded(false);
        success && setWindows(0);
      }, 2000);
    }
  }, [memberAdded, success, setWindows]);

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      gender:    sltGender ?sltGender:stylist&&stylist.gender,
      offered_services:  sltServices&&sltServices.length>0?sltServices:stylist&&stylist.offered_services
    }));
  }, [imageUrl, sltGender, makeRequest,sltServices, stylist]);
  useEffect(() => {
    if (services.length > 0) {
      setUserInputFields((prevFields) => [
        ...prevFields,
        {
          tag: "select",
          select: "multiple",
          name: "offered_services",
          type: "text",
          placeholder: "Services Offered",
          options: services,
          required: true
        }
      ]);
    }
  }, [services]);

  const addMemberValues = {
    heading: "Add team member",
    first_name: "",
    last_name: "",
    email: "",
    gender: "",
    shop_id: shopId,
    phoneNumber: "",
    address: "",
    experience: 0,
    active: false,
    image: selectedImage,
    imageCaption: "Upload the profile photo",
    buttonText: "Add Member"
  };

  const editMemberValues = {
    ...stylist,
    heading: "Edit team member",
    imageCaption: "Upload new profile photo",
    buttonText:  "Edit Member",
    isEdit:true
  };

  const values = useMemo(
    () => (windows === 2 ? editMemberValues : addMemberValues),
    [windows, selectedImage, stylist, shopId]
  );

  const validateForm = (formData) => {
    const newErrors = {};

    userInputFields.forEach((field) => {
      const value = formData.get(field.name);

      // Check if the field is required and empty
      if (field.required && !value) {
        newErrors[field.name] = `${field.placeholder} is required`;
      }

      // Additional validation for email
      if (field.name === "email_id" && value) {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(value)) {
          newErrors[field.name] = "Invalid email format";
        }
      }

      // Additional validation for phone number
      if (field.name === "phone_number" && value) {
        const phonePattern = /^[6-9]\d{9}$/;
        if (!phonePattern.test(value)) {
          newErrors[field.name] = "Invalid phone number";
        }
      }
    });

    return newErrors;
  };
  const isFormIncomplete = () => {
    const expectedFieldsCount = stylist ? 11 : 12;
    
    // Check if all form fields are filled
    const allFieldsFilled = Object.values(formData).length === expectedFieldsCount;
    
    // Check if date of birth is provided
    const dobProvided = formData.dob && formData.dob.trim() !== '';
  
    // Check if services are selected
    const servicesSelected = sltServices && sltServices.length > 0;
    const profileImgAdded = selectedImage!==undefined
  
    console.log('errosss',allFieldsFilled ,dobProvided,servicesSelected,expectedFieldsCount ,(formData.dob!=='') )
    return !(allFieldsFilled && dobProvided && servicesSelected,profileImgAdded);
  };
  console.log('errossssssssssss',sltServices,formData)


  const handleSubmit =  (e) => {
    e.preventDefault();
    setShowError(true);
// if(!makeRequest&&stylist){
//   setWindows(0)
// }


    const newFormData = new FormData(e.target);
    const newErrors = validateForm(newFormData);

    if (Object.keys(newErrors).length > 2 ) {
      setErrors(newErrors);
      return;
    }

    setErrors({});
 // Usage
if (isFormIncomplete()&&!stylist) {
  return;
}
setShowLoading(true)
    const endpoint =
    windows === 2
      ? "/professional/updateProfessional"
      : "/professional/addProfessional";
  
  makeRequest&& 
   axios
  .post(
    `${API_ENDPOINT}${endpoint}`,
    { ...formData, professional_id: stylist && stylist.professional_id },
    {
      headers: {
        Authorization: authToken,
        "Content-Type": "application/json",
        accept: "application/json"
      }
    }
  )
  .then((res) => {
    setMemberAdded(true);
    setShowLoading(false);

    if (res.status === 201) {
      setSuccess(true);
    }
  })
  .catch((err) => console.log(err));
  
  
  
  
    // } catch (err) {
    //   console.error("Image upload error", err);
    //   setMemberAdded(true);
    //   return;
    // }

    if (imageUrl) {
      // newFormData.append("profile_image_url", imageUrl);
      // const payload = {
      //   ...Object.fromEntries(newFormData.entries()), // Include other form data as usual
      //   offered_services: selectedServices,
      //   gender: selectedGender[0],
      // };
    }
  };
console.log('prop drill2', ((Object.values(formData).length !== stylist?11:12)&& (sltServices &&
  sltServices.length < 1)),formData)
  useEffect(() => {
    let imageData = new FormData();
    imageData.append("file", selectedImage);
    selectedImage&&   axios
    .post(`${API_ENDPOINT}/professional/updateProfilePic`, imageData, {
      headers: {
        Authorization: authToken,
        "Content-Type": "multipart/form-data",
        accept: "application/json"
      }
    })
    .then((res) => {
      setImgUploaded(true);
      if (res.status === 200) {
        setImageUrl(res.data.url);
      }
      setFormData((prevFormData) => ({
        ...prevFormData,
  
        profile_image_url:res.data.url
      }));
    
    })
    .catch((err) => {
      console.error("Image upload error", err);
      setMemberAdded(true);
      return;
    });
 

  }, [API_ENDPOINT, authToken, selectedImage]);

  const successMessage =
    windows === 2
      ? "Stylist updated Successfully! "
      : "New Member Successfully Added!";



  return (
    <>
      {memberAdded ? (
        <PostSuccessScreen
          successMessages={[successMessage]}
          errorMessages={["Couldn't add new member", "Try Again!"]}
          success={success}
        />
      ) : (
        <div className="px-5 py-5 absolute top-20 rounded-xl bg-black text-white w-[90vw] border">
          <div className="flex justify-between mb-6">
            <div className="text-lexend text-2xl font-medium">
              {values.heading}
            </div>
            <button onClick={() => setWindows(0)}>
              <RxCross1 />
            </button>
          </div>
          <form onSubmit={handleSubmit} onChange={handleChange}>
            <div className=" gap-3 mb -4">
              <label
                htmlFor="profileImage"
                className="flex flex-col items-center"
              >
                <img
                  src={
                    selectedImage
                      ? URL.createObjectURL(selectedImage)
                      : stylist?.profile_image_url || dummyImage
                  }
                  alt=""
                  className="h-20 w-20 rounded-full"
                />
                <div className="text-xs pb-1">{values.imageCaption}</div>
                <div
                  className={` ${
                  (!stylist&& selectedImage == undefined )&& showError ? "" : "hidden"
                  } text-[#e74138fb] capitalize text-sm`}
                >
                  Image Is Required
                </div>
              </label>
              <input
                type="file"
                name="profileImage"
                id="profileImage"
                className="hidden"
                accept="image/*"
                onChange={(event) => setSelectedImage(event.target.files[0])}
              />
            </div>
            <div className="text-black flex flex-col gap-2 h-72 pb-8 overflow-y-scroll">
              {loading ? (
                <LoadingScreen value={"Stylist Information"} />
              ) : (
                userInputFields.map((field, key) => (
                  <div
                    key={field.name}
                    className={`${key > 8 ? "hidden" : ""}`}
                  >
                    <StylistInput
                      field={field}
                      values={values}
                      key={key}
                      onChange= {handleChange}
                      setSelectedServices={setSelectedServices}
                      addSelectedGender={ [selectedGender, setSelectedGender]}
                    />
                    {errors[field.name] &&
                      (key == 6 && sltGender !== undefined ? false : true) &&
                      (key == 8 &&
                        sltServices &&
                        sltServices.length < 1
                        ? false
                        : true) &&
                      (key === 10 ? false : true) && (
                        <div className="text-[#e74138fb] capitalize text-sm">
                          {errors[field.name]}
                        </div>
                      )}
                    {((key == 6 && !stylist&& sltGender == "") ||
                      (key == 8 &&!stylist&&
                        sltServices &&
                        sltServices.length < 1)) && (
                      <p className="text-[#e74138fb] capitalize text-sm">
                        {(key === 6 || key === 8) &&
                          showError &&
                          field.name + " is required"}
                      </p>
                    )}
                  </div>
                ))
              )}
            </div>
            <button
              type="submit"
              disabled={values.isEdit&&!makeRequest&&stylist}
              className={` ${values.isEdit&&!makeRequest&&stylist?'bg-themeYellow opacity-50':"bg-themeYellow"} text-base py-3 rounded-lg text-center  w-full text-black mt-6`}
            >
              { showLoading?'Loading...': values.buttonText}
            </button>
          </form>
        </div>
      )}
    </>
  );
}
