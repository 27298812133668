// AuthContext.js
import React, { createContext, useState, useContext } from "react";

const AddMemberContext = createContext();

export const AddMemberProvider = ({ children }) => {
   const [sltGender, setSltGender] = useState()
   const [sltServices, setSltServices] = useState([])

   const handleGender = (value) =>{
    setSltGender(value)
   }

   const handleService = (services) =>{
   
    setSltServices(services)
   }

    return (
        <AddMemberContext.Provider
            value={{
                sltGender,
                sltServices,
                handleGender,
                handleService

                
            }}
        >
            {children}
        </AddMemberContext.Provider>
    );
};

export const useAddMember = () => useContext(AddMemberContext);
