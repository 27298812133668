import React,{useEffect,useContext,useState} from 'react'
import { RxCross1 } from "react-icons/rx";
import stylists from '../data/stylists';
import { IoPeopleOutline } from "react-icons/io5";
import {ReactComponent as ActiveUser} from '../assets/appointment/activeUser.svg'
import axios from 'axios';
import { AuthContext } from '../contexts/useAuth';
import LoadingScreen from './LoadingScreen';
import NullMessage from './NullMessage';
import FilterStylistCard from './FilterStylistCard';
import { useNavigate } from 'react-router-dom';

export default function SortBy({ filterOptions, setFilterOptions, setFilter }) {
  const {authToken,shopId} = useContext(AuthContext)
  const [availableStylists,setAvailableStylists] = useState([])
  const API_ENDPOINT = process.env.REACT_APP_API_URL;
  const [loading,setLoading] = useState(true)
  const [nullMessage,setNullMessage] = useState(false);

const navigate = useNavigate();
  const handleNavigate = () => {
    navigate(`/appointments`);
  }

  useEffect(() => {
    axios.get(`${API_ENDPOINT}/professional/getAvailableStylist?shop_id=${shopId}`,
        {
            headers: {
                "Authorization": authToken,
                "Content-Type": "application/json",
                accept: "application/json",
            }
        }
    ).then(res => {
        console.log(res)
        if(res.status == 201){
          setAvailableStylists(res.data.availableStylists)
          setLoading(false)
          
        }
    })
        .catch(err =>{
          console.log('error: ', err)
          setLoading(false)
          setNullMessage(true)

        } )
}, [])
  return (
    <div className={`absolute w-[92vw] bottom-0 rounded-t-xl bg-white px-3 pt-6 pb-8 z-index-40 transition duration-300 ease-in-out ${filterOptions ? 'translate-y-0' : 'translate-y-full'}`}>
      <div className='flex justify-between px-3 mb-4 items-center'>
        <div className='font-medium text-2xl'>
          Sort by
        </div>
        <button onClick={() => setFilterOptions(false)}><RxCross1 /></button>
      </div>
      <div className='text-base font-medium overflow-auto h-[70vh]'>
        <button className='text-center px-3 py-6 border-b border-red-300 flex gap-3 items-center w-full' onClick={()=>{handleNavigate(); setFilter(-2);setFilterOptions(false)}}>
          <div className='h-10 w-10 p-2  bg-grey rounded-full flex items-center justify-center text-2xl '>
            <IoPeopleOutline/>
          </div>
          <div className='text-base'>View All Members</div>
        </button>
        {/* <button className='text-center px-3 py-6 border-b border-red-300 flex gap-3 items-center w-full' onClick={()=>{setFilter(-1);setFilterOptions(false)}}>
          <div className='h-10 w-10 p-2 bg-grey rounded-full'>
            <ActiveUser/>
          </div>
          <div className='text-base'>Active Members</div>
        </button> */}
        
        {loading ? <LoadingScreen value={"Stylists"} dark={true}/> :
        nullMessage ? <NullMessage value={"No Professionals found!"}/>:
        availableStylists.map(stylist =>
          <FilterStylistCard stylist={stylist} setFilter={setFilter} setFilterOptions={setFilterOptions}/>
        )}

      </div>
    </div>
  )
}
